import React, { useEffect, useRef } from "react";

const TitleField = ({value=null, onChange=function(){}}) => {
    const titleRef = useRef(null);

    useEffect(()=> {
        if(value !== null){
            titleRef.current.value = value;
        }
    }, [value])

    return <>
        <div className="flex justify-center w-full mb-3 items-center">
            <label htmlFor="title" className="text-lg">Title:</label>
            <input ref={titleRef} type="text" placeholder="Title..." id="title" name="title" className="ml-3 w-full border border-solid border-slate-300 rounded-md p-2" onChange={(e)=> { onChange(e.target.value); }} />
        </div>
    </>
}

export default TitleField;