import { createSlice } from '@reduxjs/toolkit'

export const changer = createSlice({
  name: 'changer',
  initialState: {
    value: 0,
    update: 0,
    sidebar: "blogs",
    id: ""
  },
  reducers: {
    SideBarValue: (state, actions)=> {
      state.sidebar = actions.payload;
    },
    setUpdate: (state, action) => {
      state.update = action.payload;
    },
    setID: (state, action) => {
      state.id = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { SideBarValue, setUpdate, setID } = changer.actions;

export default changer.reducer