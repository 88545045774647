function getDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

// Example usage
// const formattedDate = getCurrentDateDMY();
// console.log(formattedDate); // Output: Current date in "day-month-year" format (e.g., "15-06-2024")
export { getDate }
