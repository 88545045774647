import { createSlice } from "@reduxjs/toolkit";

export const editor = createSlice({
    name: "editor",
    initialState: {
        title: '',
        content: '',
        image: '',
        home_image: '',
        type: "private",
        date: "",
        tags: []
    },
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setContent: (state, action) => {
            state.content = action.payload;
        },
        setImage: (state, action) => {
            state.image = action.payload;
        },
        setHomeImage: (state, action) => {
            state.home_image = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setTags: (state, action) => {
            state.tags = action.payload;
        },
        setEmpty: (state) => {
            state.title = "";
            state.content = "";
            state.image = "";
            state.home_image = "";
            state.type = "private";
            state.date = "";
            state.tags = [];
        }
    }
});

export const { setTitle, setContent, setImage, setHomeImage, setType, setDate, setEmpty, setTags } = editor.actions;

export default editor.reducer;