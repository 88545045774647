import React, { useEffect, useState } from "react";
import axios from "axios";

// components
import BlogCard from "../Components/Cards/BlogCard";
import SearchBar from "../Components/SearchBar";

const BlogPage = () => {
    const [Blogs, setBlogs] = useState([]);
    const [Store, setStore] = useState([]);

    const Api = async () => {
        const resp = await axios.get(`https://aestraswift.ocpl.tech/ocplWebApi/blogs`);
        const data = resp.data.reverse();
        setBlogs(data);
        setStore(data);
    }

    useEffect(() => {
        Api();
    }, []);

    return <>
        <div className="flex justify-center my-4 items-center">
            <SearchBar array={Store} onSearch={(e)=> { setBlogs(e); }} />
        </div>
        <div className="flex justify-center flex-wrap items-center p-3">
            {
                Blogs.length !== 0 ? Blogs.map((data, index) => {
                    return <BlogCard title={data?.title} image={data.home_image ? data.home_image: data.image} data={data} id={data?._id} key={index} />
                }): <h2 className="my-6 font-bold leading-normal not-italic text-2xl">Blogs not Found!</h2>
            }
        </div>
    </>
}

export default BlogPage;