import React from "react";

// icons
import { FaPlus } from "react-icons/fa6"; // plus

// components
import Button from "../Buttons/Button";

const CreateBlogMessage = ({value=function(){}}) => {
    return <>
        <div className="flex flex-col justify-center items-center w-full h-full">
            <h2 className="text-3xl text-slate-900 font-bold uppercase">Create A New Blog-Post</h2>

            <p className="not-italic text-slate-800 font-normal text-center leading-normal my-3 w-[90%]">Creating high-quality blog posts is crucial for attracting and retaining readers, establishing authority in your niche, and driving traffic to your website. Our guide offers practical tips and actionable insights to help you write blog posts that inform, entertain, and convert. Start your journey to becoming a blogging pro with our comprehensive guide. Happy blogging!</p>
            
            <Button color="green" className="px-6 mt-2" onClick={value}>
                <FaPlus size={"25px"} />
            </Button>
        </div>
    </>
}

export default CreateBlogMessage;