import React, { useEffect, useRef } from "react";
import Button from "../Buttons/Button";
import { getDate } from "../../functions/getDate";

const DateField = ({ value=null, onChange=function(){} }) => {
    const dateRef = useRef(null);
    useEffect(()=>{
        if(value !== null){
            dateRef.current.value = value;
        }
    }, [value]);
    return <>
        <div className="flex justify-center w-full items-center">
            <label htmlFor="date" className="text-lg">Date:</label>
            <input ref={dateRef} type="text" placeholder="Date..." id="date" name="date" className="ml-3 w-full border border-solid border-slate-300 rounded-md p-2" onChange={(e)=> { onChange(e.target.value); }} />
            <Button color="green" className="ml-2" onClick={(e)=> {
                e.preventDefault();
                let date = getDate();
                dateRef.current.value = date;
                onChange(date);
            }}>Auto</Button>
        </div>
    </>
}

export default DateField;