import React from "react";
import Button from "./Button";

const TypeButton = ({type="", onClick=function(){}}) => {
    return <>
        <div className="flex justify-center my-3 items-center">
            <h2 className="text-lg font-bold not-italic leading-normal mr-2">Type:</h2>
            <div>
            {
                type === "hide" ? <>
                    <Button className="hide" color="red" onClick={()=> onClick({type: "private"}) }>hide</Button>
                </>: <>
                    <Button className="uppercase" onClick={()=>onClick({type: "hide"})}>no-hide</Button>
                </>
            }
            </div>
        </div>
    </>
}

export default TypeButton;