import React, { useEffect, useRef } from "react";

const Authentication = ({ onLogin = function () { } }) => {
    // ref
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    // functions
    const onSubmit = (e) => {
        e.preventDefault();
        let email = emailRef.current.value;
        let password = passwordRef.current.value;
        // console.log(email, password);
        if(email === "ocpltech@gmail.com" && password === "ocpltech@123"){
            if(!localStorage.getItem("ocpl-blog-auth")){
                localStorage.setItem("ocpl-blog-auth", JSON.stringify({ email: "ocpltech@gmail.com", password: "ocpltech@123" }));
            }
            onLogin(true);
        }
    }

    useEffect(()=> {
        try{
            if(localStorage.getItem("ocpl-blog-auth")){
                let auth = JSON.parse(localStorage.getItem("ocpl-blog-auth"));
                if(auth.email === "ocpltech@gmail.com" && auth.password === "ocpltech@123"){
                    onLogin(true);
                }
            }
        }catch(err){}
    }, []);

    return <>
        <div className="flex justify-center fixed top-0 left-0 bg-white items-center h-screen w-full">
            <section className="bg-gray-50 w-1/2 dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <a className="flex items-center w-full text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="w-20" src="/ocpl_logo.png" alt="logo"/>
                    </a>
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Login in to your account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
                                <div>
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input type="email" ref={emailRef} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""/>
                                </div>
                                <div>
                                    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input type="password" ref={passwordRef} name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                                </div>
                                <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}

export default Authentication;