import React, { useRef } from "react";

// icons
import { IoSearchOutline } from "react-icons/io5"; // search
import { RxCross2 } from "react-icons/rx"; // cross 

const SearchBar = ({array={}, onSearch=function(){}}) => {
    // ref
    const inputRef = useRef(null);

    const SearchEvent = (e) => {
        let text = e.target.value.toLowerCase();
        let _array = [];

        array.map((value)=> {
            let title = value.title.toLowerCase();
            if(title.match(text)){
                _array.push(value);
            }
        });

        if(text === ""){
            onSearch(array);
        }else {
            onSearch(_array);
        }
    }

    const CancleEvent = () => {
        inputRef.current.value = "";
        onSearch(array);
    }

    return <>
        <div className="flex justify-center items-center bg-white p-2 border border-solid shadow-lg border-slate-50 rounded-lg">
            <label for="search" className="cursor-pointer mr-2">
                <IoSearchOutline size={"22px"} />
            </label>
            <input ref={inputRef} id="search" placeholder="Search..." className="bg-transparent outline-none border-b border-solid border-b-transparent active:border-b-blue-600" type="text" name="search" onChange={SearchEvent} />
            <button className="cursor-pointer ml-2" onClick={CancleEvent}>
                <RxCross2 size={"22px"} />
            </button>
        </div>
    </>
}

export default SearchBar;