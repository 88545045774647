import React, { useState } from 'react';
import axios from 'axios';
import './CreateBlog.css';

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([{ question: '', answer: '' }]);
  const [image, setImage] = useState(null);
  const [link, setLink] = useState('');

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { question: '', answer: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', JSON.stringify({ questions }));
      formData.append('image', image);
      formData.append('link', link);


      await axios.post('https://Bot.ocpl.tech/api/v1/user/blogs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setTitle('');
      setQuestions([{ question: '', answer: '' }]);
      setImage(null);
      setLink('');
      alert('Blog created successfully!');
    } catch (error) {
      console.error('Error creating blog:', error.message);
      alert('Failed to create blog. Please try again.');
    }
  };

  return (
    <div className="create-blog-container">
      <h2>Create New Blog</h2>
      <form onSubmit={handleSubmit} className="create-blog-form">
        <div className="form-group">
          <label>Title:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Questions & Answers:</label>
          {questions.map((qna, index) => (
            <div key={index}>
              <input
                type="text"
                placeholder="Question"
                value={qna.question}
                onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
              />
              <input
                type="text"
                placeholder="Answer"
                value={qna.answer}
                onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={addQuestion}>Add Question</button>
        </div>
        <div className="form-group">
          <label htmlFor="image-upload">Upload Image:</label>
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Link:</label>
          <input type="text" value={link} onChange={(e) => setLink(e.target.value)} />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default CreateBlog;
