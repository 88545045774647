import React from "react";

const Button = ({color="", className="", children, onClick=function(){}}) => {
    return <>
        {
            color === "red" ? <button className={"bg-red-700 hover:bg-red-800 py-2 px-3 rounded-md cursor-pointer text-white" + (className !==  "" ? ` ${className}`: "")} onClick={onClick}>{children}</button>:
            color === "blue" ? <button className={"bg-blue-700 hover:bg-blue-800 py-2 px-3 rounded-md cursor-pointer text-white" + (className !==  "" ? ` ${className}`: "")} onClick={onClick}>{children}</button>:
            color === "green" ? <button className={"bg-green-700 hover:bg-green-800 py-2 px-3 rounded-md cursor-pointer text-white" + (className !==  "" ? ` ${className}`: "")} onClick={onClick}>{children}</button>:
            color === "orange" ? <button className={"bg-orange-700 hover:bg-orange-800 py-2 px-3 rounded-md cursor-pointer text-white" + (className !==  "" ? ` ${className}`: "")} onClick={onClick}>{children}</button>:
            <button className={"bg-purple-700 hover:bg-purple-800 py-2 px-3 rounded-md cursor-pointer text-white" + (className !==  "" ? ` ${className}`: "")} onClick={onClick}>{children}</button>
        }
    </>
}

export default Button;