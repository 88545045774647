import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";

const TagsField = ({value=[], onChange=function(){}}) => {
    const [selected, setSelected] = useState([]);

    useEffect(()=> {
        if(value.length !== 0){
            setSelected(value);
        }
    }, [value]);

    return <>
        <TagsInput
            value={selected}
            onChange={(e) => {
                setSelected(e);
                onChange(e);
            }}
            name="tags"
            placeHolder="Enter tags"
        />
    </>
}

export default TagsField;