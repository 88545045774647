import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// icons
import { RxHamburgerMenu } from "react-icons/rx"; // burger
import { FaUndo } from "react-icons/fa"; // undo
import { FaRedo } from "react-icons/fa"; // redo
import { FaSave } from "react-icons/fa"; // save
import { FaBlog } from "react-icons/fa"; // blogs
import { MdEditNote } from "react-icons/md"; // edit

// redux
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../features/editor";
import { SideBarValue } from "../features/changer";

const BlogEditorNavbar = () => {
    // redux
    const title = useSelector(state => state.editor.title);
    const sidebar = useSelector(state => state.changer.sidebar);
    const dispatch = useDispatch();

    // ref
    const titleRef = useRef(null);

    // function
    const ChangeTitle = (e) => {
        let text = e.target.value;
        dispatch(setTitle(text));
    }

    const Button = ({ icon, text = "", light = false }) => {
        return <>
            <Link to={"/"+text.toLowerCase()}>
                <button className={"flex justify-center flex-col text-white items-center transition-all p-2 mx-2 rounded-md hover:bg-slate-700" + (light ? ` bg-slate-700` : "")} title={text} onClick={() => {
                    text = text.toLowerCase();
                    dispatch(SideBarValue(text));
                }}>
                    {icon}
                    {/* <span className="mt-1">{text}</span> */}
                </button>
            </Link>
        </>
    }

    useEffect(() => {
        try {
            titleRef.current.value = title;
        } catch (err) { }
    }, [title]);

    return <>
        <nav className="flex justify-between items-center p-1 w-full bg-slate-800">
            <img src="/ocpl_logo.png" alt="logo" className="w-[70px] cursor-pointer" />
            <div className="flex justify-center items-center ml-4">
                <Button icon={<FaBlog size={"25px"} />} text="Blogs" light={window.document.location.pathname.match("blogs")} />
                <Button icon={<MdEditNote size={"30px"} />} text="Edit" light={window.document.location.pathname.match("edit")} />
            </div>
        </nav>
    </>
}

export default BlogEditorNavbar;