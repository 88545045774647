import React, { useEffect, useRef } from "react";

// icons
import { RxCross2 } from "react-icons/rx"; // cross

/**
 * 
 * @params yes="", no="", display=true|false, className="", value=function(){ ... } 
 * @returns value={()=>{ return `true | false` }}
 * @example
 * <ConfirmMessage yes="Yes, I'm Sure?" no="No, I'm Not Sure!" className="flex-col" display={true} value={(e)=>{
        console.log(e);
    }}>
    <h2>Confirm Message!</h2>
    </ConfirmMessage>
 */
const ConfirmMessage = ({ yes = "", no = "", className = "", display = false, value = function () { }, children }) => {
    // global
    const open = "fixed top-0 z-30 left-0 w-full h-full overflow-hidden duation-700 transition-all";
    const close = "fixed top-0 z-30 left-0 w-0 h-0 overflow-hidden duation-700 transition-all";

    // ref
    const displayRef = useRef(null);

    // functions
    const CloseDisplay = (e) => {
        let id = e.target.id;
        if (id === "confirm-message") {
            value(false);
        }
    }

    useEffect(() => {
        if (display) {
            displayRef.current.className = open;
        } else {
            displayRef.current.className = close;
        }
    }, [display]);

    return <>
        <div ref={displayRef} className="fixed top-0 z-30 left-0 w-0 h-0">
            <div className="flex justify-center items-center w-full h-full backdrop-brightness-75 backdrop-blur-[2px]" id="confirm-message" onClick={CloseDisplay}>
                <div className="bg-white w-3/4 md:w-1/2 rounded-md p-3 flex flex-col justify-center items-center border border-solid border-slate-100">
                    {/* main */}
                    <div className="w-full flex justify-end items-center">
                        <button className="cursor-pointer text-slate-800" onClick={() => { value(false) }}>
                            <RxCross2 size={"25px"} />
                        </button>
                    </div>
                    <div className={"flex justify-center items-center mb-2" + (className !== "" ? ` ${className}` : "")}>
                        {children}
                    </div>
                    <div className="flex justify-center items-center">
                        <button className="py-2 px-3 bg-slate-50 hover:bg-slate-100 rounded-md border border-solid border-slate-800 text-slate-800 cursor-pointer" onClick={() => { value(false) }}>{no}</button>
                        <button className="py-2 px-3 bg-red-700 rounded-md ml-3 hover:bg-red-800 border border-solid border-red-800 text-white cursor-pointer" onClick={() => { value(true) }}>{yes}</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ConfirmMessage;