import React, { useState } from "react";

// components
import Authentication from "./Components/Authentication";
import CreateBlogMessage from "./Components/Message/CreateBlogMessage";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const [Login, setLogin] = useState(false);
    const navigate = useNavigate();

    return <>
        <div className="w-full flex justify-center items-center flex-col">
            {
                Login ? <>
                    <div className="flex w-full h-[87.5vh] justify-center items-center">
                    <CreateBlogMessage value={() => {
                        navigate("/edit");
                    }} />
                    </div>
                </> : <>
                    <Authentication onLogin={()=> { setLogin(true) }} />
                </>
            }
        </div>
    </>
}

export default Home;