import React from "react";
import Button from "../Buttons/Button";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const BlogCard = ({ image = "", id = "", title = "" }) => {
    return <>
        <div className="flex flex-col justify-center w-[90%] mx-4 md:w-[35%] lg:w-[25%] items-center border border-solid p-2 border-slate-200 cursor-pointer  shadow-md shadow-slate-100 rounded-md bg-white my-10">
            <img src={image} alt="image" className="w-full h-32 rounded-md -mt-5" />
            <h2 className="my-2 font-semibold not-italic leading-normal">{title}</h2>
            <Link to={"/edit/" + id} className="w-full">
                <Button color="blue" className="rounded-sm w-full flex justify-center items-center">
                    Edit
                    <FaExternalLinkAlt size={"18px"} className="ml-2" />
                </Button>
            </Link>
        </div>
    </>
}

export default BlogCard;
