import React, { useEffect, useRef, useState } from "react";

// icons
import { FiUploadCloud } from "react-icons/fi"; // upload

const ImageSelector = ({value=null, onChange=function(){}}) => {
    // ref
    const fileRef = useRef(null);

    // state
    const [Img, setImg] = useState(null);

    const SelectFile = (e) => {
        let file = e.target.files[0];
        onChange(file);
        try {
            // console.log(file);
            const fileReader = new FileReader();
            fileReader.onload = function (e) {
                const file = e.target.result;
                setImg(file);
            }
            fileReader.readAsDataURL(file);
        } catch (er) { }
    }

    useEffect(()=> {
        if(value !== null){
            setImg(value);
        }
    }, [value]);

    return <>
        <div className="flex justify-center items-center bg-slate-200 border border-solid border-slate-200 my-3 hover:bg-slate-300 cursor-pointer rounded-md w-full" onClick={() => { fileRef.current.click(); }}>
            {
                Img !== null ? <>
                    <img src={Img} alt="image" className="w-full" />
                </> : <>
                    <div className="flex flex-col justify-center my-10 items-center">
                        <FiUploadCloud size={"25px"} />
                        <span className="font-semibold">Image Upload</span>
                    </div>

                </>
            }
        </div>
        <input ref={fileRef} type="file" className="hidden" onChange={SelectFile} />
    </>
}

export default ImageSelector;