import React from "react";
import './App.css';
import CreateBlog from './blog';
import Home from "./Home";
import BlogPage from "./pages/Blogpage";
import { Route, Routes } from "react-router-dom";
import BlogUploader from "./Components/Forms/BlogUploader";

function App() {
  return (
    <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="blogs" element={<BlogPage />} />
        </Route>
        <Route path="/edit">
          <Route index element={<BlogUploader />} />
        </Route>
        <Route path="/edit/:id">
          <Route index element={<BlogUploader id={"id"} />} />
        </Route>
      </Routes>
  );
}

export default App;
