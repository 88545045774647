import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";

import DateField from "../Fields/DateField";
import ImageSelector from "../ImageSelector";
import TitleField from "../Fields/TitleField";
import TextEditor from "../TextEditor";
import TagsField from "../TagsField";
import Button from "../Buttons/Button";
import axios from "axios";
import TypeButton from "../Buttons/TypeButton";
import ConfirmMessage from "../Message/ConfirmMessage";

const BlogUploader = ({ id = "" }) => {
    const navigate = useNavigate();

    // fields
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    // const [home_image, setHomeImg] = useState(null);
    const [image, setImage] = useState(null);
    const [content, setContent] = useState("");
    const [tags, setTags] = useState([]);
    const [Type, setType] = useState("private");
    const [ID, setID] = useState("");

    // messages
    const [ConfirmDelete, setConfirmDelete] = useState(false);
    const [ConfirmUpload, setConfirmUpload] = useState(false);
    const [ConfirmEdit, setConfirmEdit] = useState(false);

    const [titleValue, setTitleValue] = useState("");
    const [dateValue, setDateValue] = useState("");
    // const [home_imageValue, setHomeImgValue] = useState(null);
    const [imageValue, setImageValue] = useState(null);
    const [contentValue, setContentValue] = useState("");
    const [tagsValue, setTagsValue] = useState("");

    const Publish = async () => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("date", date);
        // formData.append("home_image", image);
        formData.append("image", image);
        // formData.append("content", content.replaceAll("background-color:#ffffff;", "background-color:#0000;").replaceAll("color:#0e121d;", "color: #ffffff;"));
        formData.append("content", content);
        formData.append("tags", JSON.stringify(tags));
        formData.append("type", Type === "private" ? "publish" : Type);

        if(title !== "" && date !== ""){
            const resp = await axios.post("https://aestraswift.ocpl.tech/ocplWebApi/blogpost", formData);
            const data = resp.data;
            console.log(data);
            navigate("/");
        }
    }

    const EditEvent = async () => {
        const formData = new FormData();
        formData.append("title", title !== "" ? title : titleValue);
        formData.append("date", date !== "" ? date : dateValue);
        // formData.append("home_image", home_image !== null ? home_image : home_imageValue);
        formData.append("image", image !== null ? image : imageValue);
        // formData.append("content", content !== "" ? content.replaceAll("background-color:#ffffff;", "background-color:#0000;").replaceAll("color:#0e121d;", "color: #ffffff;") : contentValue.replaceAll("background-color:#ffffff;", "background-color:#0000;").replaceAll("color:#0e121d;", "color: #ffffff;"));
        formData.append("content", content !== "" ? content : contentValue);
        formData.append("tags", tags.length !== 0 ? JSON.stringify(tags) : JSON.stringify(tagsValue));
        formData.append("type", Type === "private" ? "publish" : Type);

        const resp = await axios.put(`https://aestraswift.ocpl.tech/ocplWebApi/edit/${ID}`, formData);
        const data = resp.data;
        console.log(data);
        navigate("/");
    }

    const DeleteEvent = async () => {
        console.log(ID);
        const resp = await axios.delete(`https://aestraswift.ocpl.tech/ocplWebApi/deleteBlog/${ID}`);
        const value = resp.data;
        console.log(value);
        navigate("/");
    }

    const getBlogs = async (id) => {
        const resp = await axios.get("https://aestraswift.ocpl.tech/ocplWebApi/blogs");
        let blog = {};
        try {
            resp.data.filter((data) => {
                if (id === data._id) {
                    blog = data;
                }
            })
            console.log(blog);
            setTitleValue(blog?.title);
            setContentValue(blog?.content);
            setImageValue(blog?.image);
            // setHomeImgValue(blog?.home_image);
            setTagsValue(blog?.tags);
            setDateValue(blog?.date);
            setType(blog?.type);
        } catch (err) { }
    }

    useEffect(() => {
        if (id !== "") {
            let path = window.document.location.pathname.split("/");
            let _id = path[path.length - 1];
            // console.log(_id);
            setID(_id);
            getBlogs(_id);
        }
    }, [id]);

    return <>
        {/* upload */}
        <ConfirmMessage yes="Yes, I'm Sure?" no="No, I'm not Sure?" className="flex-col" display={ConfirmUpload} value={(e)=> {
            if(e){
                Publish();
            }
            setConfirmUpload(false);
        }}>
            <IoCloudUploadOutline size={"60px"} className="mb-2 text-slate-800" />
            <h2 className="text-lg font-semibold not-italic leading-normal text-slate-900">Are you sure? Publish this Blog-Post?</h2>
        </ConfirmMessage>

        {/* edit */}
        <ConfirmMessage yes="Yes, I'm Sure?" no="No, I'm not Sure?" className="flex-col" display={ConfirmEdit} value={(e)=> {
            if(e){
                EditEvent();
            }
            setConfirmEdit(false);
        }}>
            <MdEdit size={"40px"} className="mb-2 text-slate-800" />
            <h2 className="text-lg font-semibold not-italic leading-normal text-slate-900">Are you sure? Edit this Blog-Post?</h2>
        </ConfirmMessage>

        {/* delete */}
        <ConfirmMessage yes="Yes, I'm Sure?" no="No, I'm not Sure?" className="flex-col" display={ConfirmDelete} value={(e)=> {
            if(e){
                DeleteEvent();
            }
            setConfirmDelete(false);
        }}>
            <MdDeleteOutline size={"60px"} className="mb-2 text-slate-800" />
            <h2 className="text-lg font-semibold not-italic leading-normal text-slate-900">Are you sure? Delete this Blog-Post?</h2>
        </ConfirmMessage>

        <div className="w-full flex flex-col justify-center p-3 items-center">
            <div className="flex flex-col md:flex-row justify-between w-full items-center">
                <div className="flex flex-col md:w-[45%] justify-start items-start">
                    <TitleField value={titleValue} onChange={e => setTitle(e)} />
                    <DateField value={dateValue} onChange={e => setDate(e)} />
                    <TypeButton type={Type} onClick={e => setType(e.type)} />
                </div>
                {/* <div className="flex flex-col w-full md:w-[45%] mt-4 md:mt-auto justify-center items-center">
                    <h2 className="text-lg font-bold">Home_Image:</h2>
                    <div className="w-full">
                        <ImageSelector value={home_imageValue} onChange={(e) => {setHomeImg(e)}} />
                    </div>
                </div> */}
            </div>

            <div className="w-full my-3">
                <ImageSelector value={imageValue} onChange={(e) => {setImage(e)}} />
            </div>

            <div className="flex flex-col justify-center mt-5 w-full items-center">
                <TextEditor value={contentValue} onChange={e => setContent(e)} />
                <div className="flex flex-col w-full justify-start mt-4 items-start">
                    <h2 className="text-lg font-bold">Tags:</h2>
                    <TagsField value={tagsValue} onChange={e => setTags(e)} />
                </div>
            </div>

            <div className="w-full flex my-4 justify-start items-center">
                {
                    id !== "" ? <>
                        <Button color="green" onClick={()=> { setConfirmEdit(true); }}>Edit</Button>
                    </> : <>
                        <Button color="blue" className="uppercase" onClick={()=> { setConfirmUpload(true); }}>Publish</Button>
                    </>
                }
                {
                    id !== "" ? <Button color="red" className="ml-2" onClick={()=> { setConfirmDelete(true); }}>Delete</Button> : null
                }
            </div>
        </div>
    </>
}

export default BlogUploader;